.home-container {
  background-color: pink;
  width: 100vw;
  height: 100vh;
}

.eight-ball {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 10%;
}

.eight-ball img {
  width: 10rem;
  z-index: 10;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.released {
  animation: shake 3s;
  /* animation: rotate 3s cubic-bezier(0.68, -0.55, 0.265, 1.8) 1; */
  transition: transform 0.5s ease-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(1080deg);
  }
  95% {
    transform: rotate(1060deg);
  }
  96% {
    transform: rotate(1070deg);
  }
  97% {
    transform: rotate(1075deg);
  }
  98% {
    transform: rotate(1079deg);
  }
  99% {
    transform: rotate(1079.9deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  2%,
  6%,
  10%,
  14%,
  18%,
  22%,
  26%,
  30%,
  34%,
  38%,
  42%,
  46%,
  50%,
  54%,
  58%,
  62%,
  66%,
  70%,
  74%,
  78%,
  82%,
  86%,
  90%,
  94%,
  98% {
    transform: translate(-2px, 0);
  }
  1%,
  5%,
  9%,
  13%,
  17%,
  21%,
  25%,
  29%,
  33%,
  37%,
  41%,
  45%,
  49%,
  53%,
  57%,
  61%,
  65%,
  69%,
  73%,
  77%,
  81%,
  85%,
  89%,
  93%,
  97% {
    transform: translate(2px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.eight-ball.held:hover::before {
  background-color: red;
  content: "";
  position: absolute;
  border: 3rem solid red;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  animation: orbit 1s linear infinite;
  filter: blur(1rem);
}

@keyframes orbit {
  0% {
    transform: translate(-1%, -1%) rotate(0) translate(2rem) rotate(-90deg);
  }
  100% {
    transform: translate(-1%, -1%) rotate(360deg) translate(2rem) rotate(-90deg);
  }
}

.box h3 {
  font-family: "Regular", sans-serif;
  font-size: small;
}

.box {
  background-color: pink;
  position: absolute;
  display: flex;
  top: 60%;
  width: 100%;
  justify-content: center;
}

.response {
  display: flex;
  background-color: rgb(90, 90, 90);
  border-radius: 1rem;
  color: white;
  text-align: left;
  width: 50%;
  height: auto;
  min-height: 10rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  margin-bottom: 100px;
}
