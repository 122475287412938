.welcome-container {
  height: 100vh;
  background-color: pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo img {
  top: 40%;
  position: relative;
  width: 10rem;
}

.button-container {
  top: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

button {
  font-family: "Heading", sans-serif;
  width: 8rem;
  border: none;
  height: 5vh;
  color: white;
  background-color: red;
}
